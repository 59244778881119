import React, { useEffect, useState } from "react";
import { deletepatientbyid, getPatientdata, updatePatientData } from "../../Service/API";
import '../../Assessts/ShowPatientdata.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import ReactLoading from 'react-loading'; 


function ShowPatientdata() {
    const [patientDetails, setPatientDetails] = useState([]);
    const [filteredPatientDetails, setFilteredPatientDetails] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showViewDetailModal, setShowViewDetailModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editedPatient, setEditedPatient] = useState({});
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [loading, setLoading] = useState(true); // New loading state

    useEffect(() => {
        getPatientDetails();
    }, []);


    const getPatientDetails = async () => {
        setLoading(true); // Activate loader
        try {
            const result = await getPatientdata();
            setPatientDetails(result.data);
            setFilteredPatientDetails(result.data);
        } catch (error) {
            console.log("Error fetching patient data", error);
        } finally {
            setLoading(false); // Set loading to false after fetching data
        }
    };


    const formatDate = (dateTimeString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateTimeString).toLocaleDateString(undefined, options);
    };

    const handleSearch = (query) => {
        const trimmedQuery = query.trim().toLowerCase();

        if (!trimmedQuery) {
            setFilteredPatientDetails(patientDetails);
        } else {
            // Filter patients whose names match the query
            const filteredPatients = patientDetails.filter(patient =>
                patient.patientname.toLowerCase().includes(trimmedQuery)
            );
            setFilteredPatientDetails(filteredPatients);
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setSearchQuery(value);
        handleSearch(value);
    };


    const deletePatientdata = async (id) => {
        try {
            await deletepatientbyid(id);
            // Remove the deleted patient from the state
            setPatientDetails(prevState => prevState.filter(patient => patient._id !== id));
            setFilteredPatientDetails(prevState => prevState.filter(patient => patient._id !== id));
        } catch (error) {
            console.log("Error deleting Blog", error);
        }
    };

    const handleEditClick = (patient) => {
        setEditedPatient(patient);
        setShowEditModal(true);
    };

    const handleUpdatePatient = async () => {
        try {
            await updatePatientData(editedPatient._id, editedPatient);
            // Update the patient details in the state
            const updatedPatientDetails = patientDetails.map((patient) =>
                patient._id === editedPatient._id ? editedPatient : patient
            );
            setPatientDetails(updatedPatientDetails);
            setFilteredPatientDetails(updatedPatientDetails);
            setShowEditModal(false); // Close the edit modal
        } catch (error) {
            console.log("Error updating Patient Data", error);
        }
    };

    const handleViewDetails = (patient) => {
        setSelectedPatient(patient);
        setShowViewDetailModal(true);
    };

    return (
        <div className="patient-container">
            <h2>Patients' Data</h2>

            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by Patient Name"
                    value={searchQuery}
                    onChange={handleChange}
                />
            </div>

           {loading ? ( // Display loader if loading state is true
                <div className="loader-container">
                    <ReactLoading type={"bars"} color={"#03fc4e"} height={100} width={100} />
                </div>
            ):(

            <table className="patient-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Phone No</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredPatientDetails.map((patient, index) => (
                        <tr key={index}>
                            <td>{patient.patientname}</td>
                            <td>{patient.patientaddress}</td>
                            <td>{patient.patientphoneno}</td>
                            <td className="button-cell">
                            <span onClick={() => handleViewDetails(patient)}><FontAwesomeIcon icon={faEye} /></span>
            <span onClick={() => deletePatientdata(patient._id)}><FontAwesomeIcon icon={faTrashAlt} /></span>
            <span onClick={() => handleEditClick(patient)}><FontAwesomeIcon icon={faEdit} /></span>
            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            )}
         

            {/* View Patient Details Modal */}
            <Modal show={showViewDetailModal} onHide={() => setShowViewDetailModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Patient Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedPatient && (
                        <>
                            <p><strong>Name:</strong> {selectedPatient.patientname}</p>
                            <p><strong>Address:</strong> {selectedPatient.patientaddress}</p>
                            <p><strong>Phone No:</strong> {selectedPatient.patientphoneno}</p>
                            <p> <strong>Date:</strong> {formatDate(selectedPatient.date)}</p>
                            <p> <strong>CO:</strong> {selectedPatient.co}</p>
                            <p> <strong>Treatment</strong></p>
                            <p> <strong>BloodSugar:</strong> {selectedPatient.investigation.bloodSugar}</p>
                            <p> <strong>HBA1C:</strong> {selectedPatient.investigation.hba1c}</p>
                            <p> <strong>Serum Creatinine:</strong> {selectedPatient.investigation.serumCreatinine}</p>
                            <p> <strong>Urine:</strong> {selectedPatient.investigation.urine}</p>
                            <p> <strong>Treatment:</strong> {selectedPatient.treatment}</p>
                            <p> <strong>Follow Up:</strong> {selectedPatient.followup}</p>
                          
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowViewDetailModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Patient Data Modal */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Patient Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                               <Form.Group controlId="patientname">
                         <Form.Label>Patient Name</Form.Label>
                        <Form.Control
                                type="text"
                                value={editedPatient.patientname || ''}
                                onChange={(e) => setEditedPatient({ ...editedPatient, patientname: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="patientaddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                value={editedPatient.patientaddress || ''}
                                onChange={(e) => setEditedPatient({ ...editedPatient, patientaddress: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="patientphoneno">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                value={editedPatient.patientphoneno || ''}
                                onChange={(e) => setEditedPatient({ ...editedPatient, patientphoneno: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="co">
                            <Form.Label>CO</Form.Label>
                            <Form.Control
                                type="text"
                                value={editedPatient.co || ''}
                                onChange={(e) => setEditedPatient({ ...editedPatient, co: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="investigation">
                            <Form.Label>Investigation</Form.Label>
                            <Form.Group controlId="bloodSugar">
                                <Form.Label>Blood Sugar</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={editedPatient.investigation ? editedPatient.investigation.bloodSugar || '' : ''}
                                    onChange={(e) => {
                                        const updatedInvestigation = { ...(editedPatient.investigation || {}), bloodSugar: e.target.value };
                                        setEditedPatient({ ...editedPatient, investigation: updatedInvestigation });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group controlId="hba1c">
                                <Form.Label>HBA1C</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={editedPatient.investigation ? editedPatient.investigation.hba1c || '' : ''}
                                    onChange={(e) => {
                                        const updatedInvestigation = { ...(editedPatient.investigation || {}), hba1c: e.target.value };
                                        setEditedPatient({ ...editedPatient, investigation: updatedInvestigation });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group controlId="serumCreatinine">
                                <Form.Label>Serum Creatinine</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={editedPatient.investigation ? editedPatient.investigation.serumCreatinine || '' : ''}
                                    onChange={(e) => {
                                        const updatedInvestigation = { ...(editedPatient.investigation || {}), serumCreatinine: e.target.value };
                                        setEditedPatient({ ...editedPatient, investigation: updatedInvestigation });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group controlId="urine">
                                <Form.Label>Urine</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={editedPatient.investigation ? editedPatient.investigation.urine || '' : ''}
                                    onChange={(e) => {
                                        const updatedInvestigation = { ...(editedPatient.investigation || {}), urine: e.target.value };
                                        setEditedPatient({ ...editedPatient, investigation: updatedInvestigation });
                                    }}
                                />
                            </Form.Group>
                        </Form.Group>



                        <Form.Group controlId="treatment">
                            <Form.Label>Treatment</Form.Label>
                            <Form.Control
                                type="text"
                                value={editedPatient.treatment || ''}
                                onChange={(e) => setEditedPatient({ ...editedPatient, treatment: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="followup">
                            <Form.Label>Follow Up</Form.Label>
                            <Form.Control
                                type="text"
                                value={editedPatient.followup || ''}
                                onChange={(e) => setEditedPatient({ ...editedPatient, followup: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleUpdatePatient}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ShowPatientdata;
