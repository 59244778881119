import '../Assessts/Services.css';
import Footer from "./Footer";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { getService } from '../Service/API';

function Services() {

  const [Service, setService] = useState([]);

  useEffect(() => {
    getServicedetails();
  }, []);


  const getServicedetails = async () => {
    const result = await getService();
    setService(result.data);
    console.log(result.data);
  };
  const [phrases, setPhrases] = useState(['DiabetesCare', 'ObesityCare' , 'FootCare' , 'KidneyCare']);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 3000); // Change the interval as needed

    return () => clearInterval(interval);
  }, [phrases]);

  return (
    <div>
    
    <div className="typing-text">
      <h1>We Provide <span style={{ display: 'inline-block', width: '130px' }}>{phrases[currentPhraseIndex]}</span></h1>
    </div>


<div className='Last-div' data-aos="fade-right">
        {Service.map((details, index) => (
          <figure key={index} className="snip1347">
            <img src={details.image.url} alt={details.title}  className="fixed-size-image" />
            <figcaption>
              <h2>{details.title}</h2>
              <p className="truncate-lines">{details.Content}</p>
              <Link to={`/Servicesshow/${details._id}`}  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <span style={{alignContent:"center"}} ></span>Read more
            </Link>
            
            </figcaption>
          </figure>
        ))}
      </div>

      <Footer />
    </div>


  )


}
export default Services;