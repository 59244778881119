import React, { useEffect, useState } from 'react';
import '../Assessts/Home.css';
import img1 from '../Images/Img2.png';
import img2 from '../Images/Help.png';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { getService } from '../Service/API';
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { useNavigate } from 'react-router-dom';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

function  Home () {
  const [Service, setService] = useState([]);
  const navigate = useNavigate();
  const whatsappURL = `https://wa.me/923129176437`;

    useEffect(() => {
    getServicedetails();
  }, []);

  useEffect(() => {
      AOS.init({
        duration: 1000, // Animation duration in milliseconds
        once: true, // Only run the animation once
        mirror: false, // Disable mirroring the animation (e.g., once when scrolling down, once when scrolling up)
      });
    }, []);


 const getServicedetails = async () => {
    const result = await getService();
    setService(result.data);
    console.log(result.data);
  };

  const handleConnectNow = () => {
    navigate('/Contactus');
  };
  
  

  
  
return (
  <div className='Container'>
  <div className="First" >
     <section className='sec1' >
      <h1>Welcome to Diabetic Clinic</h1>
      <p>Are you ready to take your health to the next level with cutting-edge medical solutions?</p>
      <p>Look no further! At Diabetic Clinic, we specialize in providing innovative medical services and solutions tailored to meet your unique needs.</p>
      <div class="button-container">
      <button onClick={handleConnectNow} >

      <span>Connect Now</span>
      </button>
      <button onClick={handleConnectNow} >Learn More</button>
      </div>
      </section> 

      <section className='sec2'>
      <img src={img1} className="First-logo" alt="logo" loading="eager"  />
      </section>

      
  </div>

  <div
      className="box"
      data-aos="fade-up"
    >
    <h1 className="h1-one">High-Quality Healthcare</h1>
    <h1 className="h1-two">Empowered by Modern Technology</h1>
    
    <p className="paragraph">
    Our technology-driven tools leverage vast medical data points to ensure the success of your healthcare initiatives.
    </p>
    
    <div className="card-container" >
      <div className="unique-div super-div" data-aos="fade-right" >
        <h3>Our Vision</h3>
        <p>Is to cure and Prevent diabetes/Obesity</p>
        <img className="super-img" src="https://i.postimg.cc/76KtYQrP/Vision2.png" alt="" />
      </div>
      
      <div className="unique-div team-div"  data-aos="fade-down" > 
        <h3>Our Mission</h3>
        <p>Our Mission is Excellence in Clinical Care, Education, Research, Community Services</p>
        <img className="team-img" src="https://i.postimg.cc/GtZQHW7R/Mission.png" alt="" />
      </div>
      
      <div className="unique-div karma-div" data-aos="fade-up">
        <h3>Our Value</h3>
        <p>Empathy, Empowerment, Evidence based for everyone for everywhere</p>
        <img className="karma-img" src="https://i.postimg.cc/hjnhPpbY/Value.png" alt="" />
      </div>
      
      <div className="unique-div calc-div" data-aos="fade-left">
      <h3>Health Estimation Utility</h3>
      <p>Employs health-related data to enhance wellness predictions</p>
        <img className="calc-img" src="https://i.postimg.cc/wxyTYKpB/Health.png" alt="" />
      </div>
    </div>
  </div>


  <div className="Second">
     <section className='sec3' data-aos="slide-up" >
      <h2>Integrated Solutions for Diabetes and Obesity</h2>
      <p>From the first day of practice we have focus on patient centric diabetes and obesity management with medication and life style modification. As we believe prevention is better than cure. We don't only control the sugars we act on preventing further progression of diabetes/obesity and its complications. We provide all specialty services related to diabetes and obesity under one roof.</p>
      <div class="button-container">
      <button onClick={handleConnectNow} >Connect Now</button>
      <button onClick={handleConnectNow} >Learn More</button>
      </div>
      </section> 

      <section className='sec4' data-aos="slide-up" >
      <img src={img2} className="Second-logo" alt="logo" />
      </section>

      
  </div>

     <div style={{ textAlign: "center" }}>
      <h2>
        We Specialized <br></br>
         Under One roof
       </h2>
       </div>

    <Link to="/Services" style={{ textDecoration: "none" }}>
      <div className='Last-div' data-aos="fade-right">
        {Service.map((details, index) => (
          <figure key={index} className="snip1347">
            <img src={details.image.url} alt={details.title}  className="fixed-size-image" />
            <figcaption>
              <h2>{details.title}</h2>
              <p className="truncate-lines">{details.Content}</p>
              <a href="#" className="read-more">Read More</a>
            </figcaption>
          </figure>
        ))}
      </div>
    </Link>

    <FloatingWhatsApp
        phoneNumber={whatsappURL}
         accountName="Assistant"
         message="Hello! How can I assist you?"
         avatar="https://images.news18.com/ibnlive/uploads/2021/09/whatsapp-1.jpg"
       />

    <Footer />
  

  </div>
  
);
}

export default Home;
