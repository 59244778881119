import React, { useEffect , useState } from "react";
import "../../Assessts/Sidebar.css";
import { Link } from "react-router-dom";

function Sidebar({ setIsSideBar }) {
  const handleCloseSign = () => {
    setIsSideBar(false);
  };    
  const handleLinkClick = () => {
    if (window.innerWidth < 1200) {
      setIsSideBar(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setIsSideBar(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setIsSideBar]);

  const [isRegistrationDropdownOpen, setRegistrationDropdownOpen] = useState(false);
  const [isTestReportsDropdownOpen, setTestReportsDropdownOpen] = useState(false);

  const handleRegistrationDropdownToggle = () => {
    setRegistrationDropdownOpen(!isRegistrationDropdownOpen);
    setTestReportsDropdownOpen(false); // Close other dropdown if open
};


const handleTestReportsDropdownToggle = () => {
    setTestReportsDropdownOpen(!isTestReportsDropdownOpen);
    setRegistrationDropdownOpen(false); // Close other dropdown if open
};


  return (
    <div className="sidebar-container">

      { window.innerWidth > 1200 ? null :
        <i className="fa fa-times crossSign" style={{ color: "white" }} onClick={handleCloseSign}></i>
}
      <div className="sidenav">
        <span className="Heading-sidebar">ADMIN</span>

        <Link to="" style={{ textDecoration: "none" }} onClick={handleLinkClick}  >
          
          <p>
            <i className="fa fa-dashboard"></i> Dashboard
          </p>
        </Link>
        <div className={`dropdown-btn ${isRegistrationDropdownOpen ? 'Admin-active' : ''}`} onClick={handleRegistrationDropdownToggle}>
                <i className="fa fa-user-plus"></i> Registrations <i className="fa fa-caret-down"></i>
            </div>
        <div className={`dropdown-container ${isRegistrationDropdownOpen ? 'open' : ''}`}>
            <Link to="/Admin/Adminblog" style={{textDecoration:'none'}} onClick={handleLinkClick} > <p>Add Blog</p></Link>
            <Link to="/Admin/Patientdata" style={{textDecoration:'none'}} onClick={handleLinkClick} > <p>Add Patient</p></Link>
            <Link to="/Admin/AddService" style={{textDecoration:'none'}} onClick={handleLinkClick} > <p>Add Service</p></Link>
            
      </div>


            <div className={`dropdown-btn ${isTestReportsDropdownOpen ? 'active' : ''}`} onClick={handleTestReportsDropdownToggle}>
                <i className="fa fa-file-text"></i> View Reports <i className="fa fa-caret-down"></i>
            </div>
            <div className={`dropdown-container ${isTestReportsDropdownOpen ? 'open' : ''}`}>
            <Link to="/Admin/ShowPatientdata" style={{textDecoration:'none'}} onClick={handleLinkClick} ><p >View Patient Data</p></Link>
            <Link to="/Admin/Showblog" style={{textDecoration:'none'}} onClick={handleLinkClick} ><p>View Blog</p></Link>
            <Link to="/Admin/ShowService" style={{textDecoration:'none'}} onClick={handleLinkClick} ><p >View Services</p></Link>
            </div> 
      </div>

    </div>
  );
}

export default Sidebar;