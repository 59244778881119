import '../../Assessts/Adminnavbar.css';
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaLock, FaSignOutAlt } from "react-icons/fa"; 
import { useAuth } from "../../Service/AuthContext";

function Adminnavbar({ setIsSideBar }) {
  const { logout } = useAuth();
  const userEmail = localStorage.getItem('userEmail');

  useEffect(() => {
    console.log("User email is ", userEmail);
  }, []);

  const handleClickNav = () => {
    setIsSideBar((prev) => !prev);
  };

  const handleLogout = () => {
    logout();
    console.log("Logout performed", logout);
  };

  return (
    <div className="Admin-navbar">
      <i className="fa fa-bars" style={{ color: "white" }} onClick={handleClickNav}></i>

      <div className="right-section">
          <div className="dropdown">
              <img
                  src={"https://via.placeholder.com/50x50.png?text=ML"}
                  alt="Profile"
                  className="round-icon"
              />

              <div className="dropdown-content">
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <img
                          src={"https://via.placeholder.com/50x50.png?text=ML"}
                          alt="Profile"
                          className="round-icon"
                      />
                      <div>
                          <p style={{ margin: 0 }}>{userEmail}</p>
                          {/* If you need to display user's name and role, you need to store them separately */}
                          {/* <p style={{ margin: 0 }}>Name: {user.name}</p>
                          <p style={{ margin: 0, fontSize: '12px' }}>Role: {user.role}</p> */}
                      </div>
                  </div>
                  <div className="horizontal-line"></div>
               
                  <div className="horizontal-line"></div>
                  <div className="anchorTagFor">
                      <Link to="/" onClick={handleLogout}>
                          <FaSignOutAlt /> Sign out
                      </Link>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}

export default Adminnavbar;
