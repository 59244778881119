import '../Assessts/Contactus.css';
import img1 from '../Images/Contactus.jpg'
import Footer from './Footer';
function Contactus() {
  return (
    <div>
     <div class="container-1">
    <div class="contact-box-1">
      <div class="left-1"  style={{ backgroundImage: `url(${img1})` }}>
       
      </div>
      <form class="right-1"  action="https://formspree.io/f/mzbndnae" method="POST">
        <h2 className='h2-1'>Contact Us</h2>
        <input name="text" class="field" placeholder="Your Name" required />
        <input name="email" class="field" placeholder="Your Email" required />
        <input name="number" class="field" placeholder="Phone" required />
        <textarea name='text' placeholder="Message" class="field" required></textarea>
        <button style={{width:'100%'}}>Send</button>
      </form>
    </div>
  </div>
      <Footer />
    </div>
  )
}
export default Contactus;