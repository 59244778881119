// Admin.js
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Adminnavbar from "./Adminnavbar";
import Sidebar from "./Sidebar";
import AdminPage from "./AdminPage";

function Admin() {
  const [isSideBarVisible, setIsSideBarVisible] = useState(true);

  const handleSetIsSideBar = (value) => {
    setIsSideBarVisible(value);
  };

  return (
    <div>
      {isSideBarVisible && <Sidebar setIsSideBar={handleSetIsSideBar} />}
      <Adminnavbar setIsSideBar={handleSetIsSideBar} />
      <div style={{ marginTop: "50px", width: "auto" }}>
        <Routes>
          <Route path="/" element={<AdminPage />} />
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </div>
  );
}

export default Admin;
